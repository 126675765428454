/* .mainBox {
  min-height: 100vh;
} */

@media (max-width: 960px) {
  .mainBox {
    margin-top: -50px;
  }
}
.headerLogo {
  height: 45px;
}

.topBar {
  height: 86px;
}

.primaryBox {
  width: 0;
  min-width: 100%;
}

@media (min-width: 1000px) {
  .primaryBox {
    min-width: 50%;
  }
}

.containerCommon {
  width: unset;
  padding-left: 3rem;
  padding-right: 3rem;
}

.containerStart {
  box-shadow: 0px 0px 16px #00000033;
  border-left: 6px solid;

  min-height: 115px;
}

.trialStart {
  border-color: #ffff00;
  background-color: #fff;
  position: relative;
  bottom: 50%;
}

.contentContainer {
  box-shadow: 0px 0px 40px white;
  border-radius: 5px;
  /* margin-bottom: 3rem; */
  min-height: 560px;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.headerContainer {
  background: #fff;
  box-shadow: 0px 4px 12.6px 0px #0000001f;
}
.announcements {
  width: 100%;
  height: 480px;
  background: white;
  padding: 0 15.5rem;
}
@media (max-width: 1500px) {
  .announcements {
    height: 400px;
  }
}
@media (max-width: 500px) {
  .announcements {
    padding: 0 1.5rem;
  }
}
.containerWrapper {
  width: 100%;
  margin: 0 auto;
}
.easyFooter {
  justify-content: flex-end;
  display: flex;
  margin-bottom: 10px;
  margin-right: 10.5rem;
}
@media (max-width: 500px) {
  .easyFooter {
    margin-right: 1.5rem;
  }
}
.announcementsLogo {
  height: auto;
  position: relative;
  top: 6rem;
  width: 100%;
  z-index: 1;
}
.announcementContainer {
  display: flex;
}
.announcementTextContainer {
  margin: 5rem 0px;
  width: 51%;
}
.announcementTitle {
  font-size: 3.5rem;
  font-weight: 600;
  font-family: 'Helvetica Neue LT Pro', sans-serif;
  color: #000000;
}
.announcementContent {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
@media (max-width: 1900px) {
  .announcementTitle {
    font-size: 3rem;
  }
  .announcementContent {
    font-size: 16px;
  }
}
@media (max-width: 1670px) {
  .announcementTitle {
    font-size: 2.75rem;
  }
  .announcementContent {
    font-size: 16px;
  }
}
@media (max-width: 1570px) {
  .announcementTitle {
    font-size: 2.5rem;
  }
  .announcementContent {
    font-size: 16px;
  }
  .announcements {
    height: 380px;
  }
}
@media (max-width: 1470px) {
  .announcementTitle {
    font-size: 2rem;
  }
  .announcementContent {
    font-size: 16px;
  }
  .announcements {
    height: 350px;
  }
}
@media (max-width: 1275px) {
  .announcementTitle {
    font-size: 1.75rem;
  }
  .announcementContent {
    font-size: 13px;
  }
  .announcements {
    height: 370px;
  }
}
@media (max-width: 1180px) {
  .announcementTitle {
    font-size: 1.5rem;
  }
  .announcementContent {
    font-size: 13px;
  }
  .announcements {
    height: 350px;
  }
  .announcementTextContainer {
    margin: 3.5rem 0px;
    width: 51%;
  }
}
@media (max-width: 1080px) {
  .announcementTitle {
    font-size: 1.5rem;
  }
  .announcementContent {
    font-size: 13px;
  }
  .announcements {
    height: 350px;
  }
  .announcementTextContainer {
    margin: 3.5rem 0px;
    width: 51%;
  }
}
@media (max-width: 1000px) {
  .containerCommon {
    padding-left: 1rem !important;
    padding-right: 1rem;
  }
}
@media (max-width: 1000px) {
  .containerCommon {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .containerWrapper {
    width: 100%;
  }
  /* .contentContainer {
    margin-bottom: 2rem;
    margin-top: 2rem;
  } */
  .announcementsLogo {
    display: none;
  }
  .announcementContainer {
    display: block;
  }
  .announcementTextContainer {
    margin: 30px 0;
    width: 100%;
  }
  .announcementTitle {
    font-size: 24px;
  }
  .announcementContent {
    font-size: 16px;
  }
}

.marginX {
  margin: 0 15.5rem;
  justify-content: center;
}

@media (max-width: 500px) {
  .marginX {
    margin: 0 0.5rem;
  }
}
.headerWrapper {
  margin-left: 15.5rem;
}
@media (max-width: 500px) {
  .headerWrapper {
    margin-left: 1.5rem;
  }
}

.announcementText {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
}

.announcementImgContainer {
  width: 49%;
}

.topSidebar {
  margin-right: 15.5rem;
}

@media (max-width: 1500px) {
  .marginX {
    margin: 0 9rem;
  }
  .headerWrapper {
    margin-left: 9rem;
  }
  .topSidebar {
    margin-right: 9rem;
  }
  .announcements {
    padding: 0 9rem;
  }
  .announcementsLogo{
    top: 2rem;
  }
}
@media (max-width: 1380px) {
  .marginX {
    margin: 0 4rem;
  }
  .headerWrapper {
    margin-left: 4rem;
  }
  .announcements {
    padding: 0 4rem;
  }
  .topSidebar {
    margin-right: 4rem;
  }
}
.loginPrimaryBox {
  margin-top: 3rem;
}
@media (max-width: 500px) {
  .announcements {
    padding: 0 1rem;
  }
}
@media (max-width: 500px) {
  .marginX {
    margin: 0 0.5rem;
  }
}
@media (max-width: 500px) {
  .headerWrapper {
    margin-left: 1rem;
  }
}