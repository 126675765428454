:root {
  --primary-color: #b91d8f;
  --secondary-color: #d02da3;
  --text-color: #000000;
  --light-gray: #f5f7fa;
  --border-color: #e1e4e8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Helvetica Neue LT Pro', sans-serif;
  /* font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif; */
}

.pageBox {
  background-color: #f5f7fa;
  color: var(--text-color);
  line-height: 1.6;
  position: inherit;
  min-height: 100vh;
  /*height:100vh;*/
}

.pageBoxOld {
  min-height: 100vh;
  position: relative;
  background: #e3f4ff;
}

/*
.topBar {
  height: 65px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: white;
}
*/

.headerLogo {
  height: 38px;
  cursor: pointer;
}

.largeContainerOld {
  background-color: var(--bs-white);
  border-radius: 4px;
  border: 1px solid var(--bs-gray-200);
  margin: 0 25rem;
  box-shadow: 0px 0px 12px 0px white;
  padding-left: 3rem;
  padding-right: 3rem;
}

.largeContainer {
  max-width: 100%;
  /*min-width: 800px;*/
  margin: 0 1.5rem 1.5rem;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 0 2rem;
  padding-top: 0px;
  /* min-height: 85vh; */
  /* margin-left: 40px; */
  /* margin-right: 40px !important; */
}

@media(min-width: 992px) {
  .subscriptionContainer {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
}

.profileUserName {
  font-family: 'Helvetica Neue LT Pro', sans-serif;
  font-size: 18px;
}

.smallContainer {
  background-color: var(--bs-white);
  box-shadow: 0px 4px 4px 0px #00000040;
  max-width: 1100px;
  max-height: 570px;
}

.profileLogo {
  background-color: white;
  border: solid grey 1px;
  border-radius: 4px;
  /* height: 50px; */
  color: #666666;
  /* font-weight: bolder; */
  width: fit-content;
  padding: 5px 15px;
  /* padding-top: 8px; */
  margin-left: 20px;
  text-align: center;
  overflow: auto;
  font-size: .875rem;
  font-family: 'Open Sans', sans-serif;
}

.marginX {
  padding: 0.7rem 1.25rem;
  width: 100%;
}

.announcements {
  width: 100%;
  height: 480px;
  background: white;
  padding: 0 15.5rem;
}

@media (max-width: 1500px) {
  .announcements {
    height: 400px;
  }
}

@media (max-width: 500px) {
  .announcements {
    padding: 0 1.5rem;
  }
}

.announcementsLogo {
  height: auto;
  position: relative;
  top: 6rem;
  width: 100%;
  z-index: 1;
}

.announcementContainer {
  display: flex;
}

.announcementTextContainer {
  margin: 5rem 0px;
  width: 51%;
}

.easyFooter {
  margin: 0 30rem;
  text-align: end;
  margin-bottom: 1rem;
}

.easyLogo {
  margin-bottom: 10px;
}

.announcementTitle {
  font-size: 3.5rem;
  font-weight: 600;
  font-family: 'Helvetica Neue LT Pro', sans-serif;
  color: #000000;
}

.announcementContent {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

@media (max-width: 1900px) {
  .announcementTitle {
    font-size: 3rem;
  }

  .announcementContent {
    font-size: 16px;
  }
}

@media (max-width: 1670px) {
  .announcementTitle {
    font-size: 2.75rem;
  }

  .announcementContent {
    font-size: 16px;
  }
}

@media (max-width: 1570px) {
  .announcementTitle {
    font-size: 2.5rem;
  }

  .announcementContent {
    font-size: 16px;
  }

  .announcements {
    height: 380px;
  }
}

@media (max-width: 1470px) {
  .announcementTitle {
    font-size: 2rem;
  }

  .announcementContent {
    font-size: 16px;
  }

  .announcements {
    height: 350px;
  }
}

@media (max-width: 1275px) {
  .announcementTitle {
    font-size: 1.75rem;
  }

  .announcementContent {
    font-size: 13px;
  }

  .announcements {
    height: 370px;
  }
}

@media (max-width: 1180px) {
  .announcementTitle {
    font-size: 1.5rem;
  }

  .announcementContent {
    font-size: 13px;
  }

  .announcements {
    height: 350px;
  }

  .announcementTextContainer {
    margin: 3.5rem 0px;
    width: 51%;
  }
}

@media (max-width: 1080px) {
  .announcementTitle {
    font-size: 1.5rem;
  }

  .announcementContent {
    font-size: 13px;
  }

  .announcements {
    height: 350px;
  }

  .announcementTextContainer {
    margin: 3.5rem 0px;
    width: 51%;
  }
}

@media (max-width: 1000px) {
  .containerCommon {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 1000px) {
  .containerCommon {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .containerWrapper {
    width: 100%;
  }

  .contentContainer {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .announcementsLogo {
    display: none;
  }

  .announcementContainer {
    display: block;
  }

  .announcementTextContainer {
    margin: 30px 0;
    width: 100%;
  }

  .announcementTitle {
    font-size: 24px;
  }

  .announcementContent {
    font-size: 16px;
  }
}

.announcementText {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
}

.announcementImgContainer {
  width: 49%;
}

@media (max-width: 1920px) {
  .announcements {
    padding: 0 9rem;
  }

  .announcementsLogo {
    top: 2rem;
  }

  /* .largeContainer {
    margin: 0 35rem;
  } */
  .easyFooter {
    margin: 0 35rem;
  }
}

@media (max-width: 1770px) {
  .announcements {
    padding: 0 9rem;
  }

  .announcementsLogo {
    top: 2rem;
  }

  /* .largeContainer {
    margin: 0 28rem;
  } */
  .easyFooter {
    margin: 0 28rem;
  }
}

@media (max-width: 1610px) {
  .announcements {
    padding: 0 9rem;
  }

  .announcementsLogo {
    top: 2rem;
  }

  /* .largeContainer {
    margin: 0 23rem;
  } */
  .easyFooter {
    margin: 0 23rem;
  }
}

@media (max-width: 1500px) {
  .announcements {
    padding: 0 9rem;
  }

  .announcementsLogo {
    top: 2rem;
  }

  /* .largeContainer {
    margin: 0 21.2rem;
  } */
  .easyFooter {
    margin: 0 21.2rem;
  }
}

@media (max-width: 1380px) {
  .announcements {
    padding: 0 4rem;
  }

  /* .largeContainer {
    margin: 0 18rem;
  } */
  .easyFooter {
    margin: 0 18rem;
  }
}

@media (max-width: 1048px) {
  .announcements {
    padding: 0 4rem;
  }

  /* .largeContainer {
    margin: 0 12rem;
  } */
  .easyFooter {
    margin: 0 12rem;
  }
}

@media (max-width: 920px) {
  .announcements {
    padding: 0 4rem;
  }

  /* .largeContainer {
    margin: 0 10rem;
  } */
  .easyFooter {
    margin: 0 10rem;
  }
}

@media (max-width: 855px) {
  .announcements {
    padding: 0 4rem;
  }

  /* .largeContainer {
    margin: 0 7rem;
  } */
  .easyFooter {
    margin: 0 7rem;
  }
}

@media (max-width: 655px) {
  .announcements {
    padding: 0 4rem;
  }

  /* .largeContainer {
    margin: 0 3rem;
  } */
  .easyFooter {
    margin: 0 3rem;
  }
}

@media (max-width: 576px) {
  .profileLogo {
    height: 45px;
    width: 45px;
  }

  .profileUserName {
    font-size: 16px;
  }

  /* .largeContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  } */
}

@media (max-width: 545px) {
  .announcements {
    padding: 0 3rem;
  }

  /* .largeContainer {
    margin: 0 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  } */
  .easyFooter {
    margin: 0 1rem;
  }
}

.isBuyFlow {
  position: relative;
  bottom: 70px;
}

.dropdownIcon {
  width: 20px;
  margin-left: 5px;
  transition: transform 0.2s ease-in-out;
}

/* Add a separate class for the open state */
.dropdownIcon[data-open="true"] {
  transform: rotate(180deg);
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 8px;
  min-width: 200px;
  z-index: 1000;
  overflow: visible;
}

.dropdownMenuItem {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  color: #000000;
}

.dropdownMenuItemLogo {
  width: 20px;
}