.sidebarRow {
  flex-grow: 1;
  display: none;
}

@media (min-width: 1000px) {
  .sidebarRow {
    display: block;
  }
}

.loginButton {
  max-width: 100px;
  flex-grow: 1;
  border-radius: 50px;
  padding: 10px;
  padding-top: 13px;
}

.switchTypeButton {
  max-width: 110px;
  flex-grow: 1;
  font-size: 14px;
  border-radius: 50px;
  /* padding: 11px 12px; */
  padding: 0px 12px;
  padding-top: 13px;
}
.languageBox {
  font-size: 0.55rem;
  margin-left: 5.5rem;
  font-family: 'Helvetica Neue LT Pro', sans-serif;
}

@media (max-width: 1380px) {
  .languageBox {
    margin-left: 0.75rem;
  }
}