@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

:root {
  --background-blue: #E3F4FF;
  --background-yellow: #FEFADF;
}
/* 
:root {
  --primary-color: #b91d8f;
  --secondary-color: #d02da3;
  --text-color: #333;
  --light-gray: #f5f7fa;
  --border-color: #e1e4e8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
} */

body {
  font-family: "Helvetica Neue LT Pro", sans-serif !important; 
}