.pageBox {
  background-color: var(--background-blue);
  height: 100vh;
}

.logo {
  height: 38pt;
}

.contentContainer {
  background-color: #fff;
  border-radius: 3px;
  margin-left: calc(100vw/2);
  left: -250px;
  width: 500px;
  height: 150px;
  max-height: 500px;
  min-width: 500px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 20px;
}

.redirectMessage {
  font-family: OpenSans, Segoe UI, SegoeUI, "Helvetica Neue LT Pro", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #3b3b3b;
  font-weight: 600;
  text-align: center;
}

.loginLink {
  display: block;
  text-decoration: underline;
  cursor: pointer;
}