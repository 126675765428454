.pageBox {
  /* background-color: var(--background-blue); */
  height: 100vh;
  background-color: #f9f0f6;
}

.logoutPageBox {
  /* background-color: var(--background-blue); */
  height: 100vh;
  background-color: #f9f0f6;
}

.contentContainer {
  background-color: var(--bs-white);
  border-radius: 8px;
  width: 500px;
  height: 200px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 20px;
}

.logoutContentContainer {
  background-color: #f9f0f6;
  width: 0px;
  height: 0px;
}

